import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatExpansionModule } from '@angular/material/expansion';
import { AdminAccessCashHandoverComponent } from '../admin-access-cash-handover/admin-access-cash-handover.component';
import { AdminAccessVisitDistanceComponent } from '../admin-access-visit-distance/admin-access-visit-distance.component';
import { AdminAccessComponent } from '../admin-access/admin-access.component';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';


@Component({
  selector: 'app-admin-portel',
  templateUrl: './admin-portel.component.html',
  styleUrls: ['./admin-portel.component.scss']
})
export class AdminPortelComponent implements OnInit {

  readonly title: string = 'Admin Portal';
  panelOpenState = false;

  constructor(private dialogRef: MatDialogRef<AdminPortelComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: UserAuthenticationService) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  permission_adminAccess() {
    const user_name = this.auth.currentUserValue.username;
    // if (user_name === "ruwann" || user_name === "ajithkul" || user_name === "achalamr" || user_name === "bileshs" ) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '100vh';
      dialogConfig.width = '100vw';
      dialogConfig.maxWidth = '100vw';

      let dialogRef = this.dialog.open(AdminAccessComponent, {
        ...dialogConfig,
        // data: {
        // }
      })
    // } else {
    //   alert("You don't have access!!!");
    // }

  }

  permission_cashHandover() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';

    let dialogRef = this.dialog.open(AdminAccessCashHandoverComponent, {
      ...dialogConfig,
      // data: {
      // }
    })

    // const data = {
    //   contractNo: this.selectedCallDeskTransaction.contractNo,
    //   cType: this.selectedCallDeskTransaction.cType,
    // };

    // const dialogRef = this.dialog.open(AdminAccessCashHandoverComponent, {
    // data: data,
    // });
  }

  permission_visitDistance() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';

    let dialogRef = this.dialog.open(AdminAccessVisitDistanceComponent, {
      ...dialogConfig,
      // data: {
      // }
    })

  }

}
